* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
}

#root {
  min-height: 100vh;
}

h1 {
  font-family: "Mochiy Pop P One", sans-serif;
}
