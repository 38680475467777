.contenedor {
  width: 95vw;
}

.mults {
  min-height: 100vh;
  display: grid;
  place-content: center;
}

.mult {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  gap: 4px;
}

.mult input {
  font-size: 2rem;
  width: 4rem;
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 5rem;
}

.ok {
  background-color: green;
  color: white;
}

.ko {
  background-color: red;
  color: white;
}
