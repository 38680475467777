@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&family=Work+Sans:wght@600;900&display=swap");

.menu {
  min-height: 100vh;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  list-style: none;
  grid-template-areas:
    "ingles multiplicaciones"
    "tablas tablas";
}

.menu li {
  border-radius: 20px;
  background-color: blueviolet;
  display: grid;
  place-content: center;
}

.menu a {
  text-decoration: none;
  color: white;
  font-family: "Mochiy Pop P One", sans-serif;
  text-align: center;
  font-weight: bold;
}

.tablas {
  grid-area: tablas;
}

.multiplicaciones {
  grid-area: multiplicaciones;
}

.ingles {
  grid-area: ingles;
}
